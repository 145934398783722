import type { PylotFrontendConfig } from '../config.d'
export const config: PylotFrontendConfig = {
    base: {
        cart_snapshot: { enabled: false },
        sellToUsForPlp: { enabled: true, url: 'sell-gold-silver' },
        product: {
            visibleListAttributes: [
                'sku',
                'price_range',
                'small_image',
                'name',
                'pricing_table'
            ],
            pricing_table: true
        },
        loqate: {
            enabled: true,
            findEndPoint:
                'https://api.addressy.com/Capture/Interactive/Find/v1.1/json3.ws',
            retrieveEndPoint:
                'https://api.addressy.com/Capture/Interactive/Retrieve/v1.2/json3.ws',
            validateEndPoint:
                'https://api.addressy.com/Cleansing/International/Batch/v1.00/json4.ws',
            api_key: ''
        },
        url: {
            baseUrl: 'https://www.usgoldbureau.com/',
            model: {
                category: { directory: 'category', suffix: '' },
                product: { directory: 'product', suffix: '' },
                cmsPage: { directory: 'content', suffix: '' },
                giftCard: { directory: 'gift-card' },
                bundle: { directory: 'bundle', suffix: '' }
            },
            media: {
                cms: 'https://mcistaging.usgoldbureau.com/media/wysiwyg/logo/usgb-logo-pwa.png',
                home: 'https://mcistaging.usgoldbureau.com/media/wysiwyg/logo/usgb-logo-pwa.png'
            },
            path: { shippingSettings: 'content/ordering-info' },
            mediaBackend: {
                enabled: true,
                baseMediaUrl: 'https://mgi.usgoldbureau.com/media/'
            }
        },
        menu: { submenu: 'cms_block', submenu_maxitems: 10 },
        wishlist: {
            enabled: true,
            multiple_wishlists: false,
            multiple_wishlist_number: 5
        },
        shipping: {
            addressCountry: ['AU, NZ, AL, DZ, EE, IR, IQ, AF'],
            includeShippingDetails: false
        },
        yotpo: {
            enabled: true,
            api_key: 'DMBr4lxgG9KUGJaDWKzMDoy1p9Cp2hdLPPaCmn0I'
        },
        customer: {
            gift_card_payment_enabled: false,
            store_credit_enabled: false
        },
        checkout_timer: { enabled: true, default_minutes: 10 },
        blog: {
            bannerCMS: '',
            timezone: 'America/Panama',
            home_page: {
                title: 'Gold and Silver News',
                breadcrumb_title: 'Latest on Silver and Gold News'
            },
            recent_blog_post_cms: 'usgb_recent_blog_posts_block',
            is_recent_blog_post_cms_enabled: true,
            investor_guide_cms: 'usgb_free_investor_kit_block',
            news_facebook_iframe_cms: 'usgb_facebook_page_plugin_block',
            is_news_facebook_iframe_enabled: false
        },
        customer_impersonation: {
            enabled: false,
            quick_links: '',
            popular_items: ''
        },
        datadog: {
            enabled: true,
            applicationId: 'e7857f83-35ef-4884-94af-2043e6bacae4',
            clientToken: 'pubf2f49e9bc71f4db821839d46ccdcacd4',
            site: 'us5.datadoghq.com',
            service: 'magento_frontend_client',
            env: 'prod'
        },
        hubspot: {
            footer_newslestter: {
                src: 'https://js.hsforms.net/forms/embed/v2.js',
                enabled: true,
                target: '#_usgb-magento-footer-form',
                region: 'na1',
                portalId: '20900522',
                formId: '3ef206ef-6d98-4fcf-9efa-25f6a1b2dc3b'
            },
            pdp_form: {
                src: 'https://js.hsforms.net/forms/embed/v2.js',
                enabled: true,
                target: '#_usgb-magento-fik-form',
                region: 'na1',
                portalId: '20900522',
                formId: 'ca8e3a01-bd22-4d36-bc97-2368059ec465'
            }
        },
        oos_subscription: { enabled: true, gdrp_enabled: false },
        portfolio_tracker: {
            enabled: true,
            website_code: 'usgb',
            portfolio_tracker_url: 'https://portfolio-tracker-live.appspot.com',
            enable_buying_program: true,
            script_url:
                'https://cdnjs.cloudflare.com/ajax/libs/pym/1.3.2/pym-loader.v1.min.js',
            portfolio_alert_block: 'portfolio_tracker_alert_message_block_pwa'
        },
        avalara: {
            address_validation_enabled: true,
            token: '5p07uabl9fsrtj42g1xtghbh5w8kb4ih',
            address_validation_enabled_countries: ['CA', 'US']
        },
        cart_min_order_amount: {
            enabled: true,
            min_amount: 99,
            shipping_limit_amount: 99
        },
        cart_min_order_notice: {
            title: 'Minimum Order Amount $99',
            notice: 'Minimum order amount is $99 and includes FREE SHIPPING. Please add additional items to your cart to check out.'
        },
        check_out_additional: {
            ira_enabled: false,
            order_comment_enabled: false
        },
        live_chat: {
            enabled: true,
            license: '9318170',
            source: 'https://cdn.livechatinc.com/tracking.js'
        },
        route: {
            cms_no_route: 'no-route',
            cms_home_page: 'home-pwa',
            cms_contact_page: 'contact-us'
        },
        live_price: {
            live_price_script:
                'https://s3.amazonaws.com/media.usgoldbureau.com/spotprice.min.js',
            enabled_urls: ['live-gold-prices', 'live-silver-prices']
        },
        riskified_beacon: {
            enabled: true,
            beacon_url:
                'https://beacon.riskified.com/?shop=usgoldbureau.com&sid='
        }
    },
    preload: {
        mobileMenu: {
            queryPath: 'framework/pylot/hooks/cms/graphql/getCMSBlocks',
            variables: { identifiers: ['megamenu_mobile_pwa'] }
        },
        desktopMenu: {
            queryPath: 'framework/pylot/hooks/cms/graphql/getCMSBlocks',
            variables: { identifiers: ['megamenu_pwa'] }
        },
        footer: {
            queryPath: 'framework/pylot/hooks/cms/graphql/getCMSBlocks',
            variables: {
                identifiers: [
                    'footer-copyright-block',
                    'footer_links',
                    'footer-social-media'
                ]
            }
        },
        contactUs: {
            queryPath: 'framework/pylot/hooks/cms/graphql/getCMSBlocks',
            variables: { identifiers: ['header_contact_us_block_pwa'] }
        },
        menu: { variables: { category_uid: 'Nw==', onlyTopLevel: true } },
        home: {
            images: [
                'https://mgi.usgoldbureau.com/media/wysiwyg/homepage/hp-banner-industry-expertise-desktop-1360x615px.jpg?quality=80&auto=webp&format=pjpg'
            ]
        }
    },
    seo: {
        default: {
            title: 'Powered by USGB Commerce',
            twitter: {
                site: '@USGoldBureau',
                cardType: 'summary',
                creator: '@GoCorra',
                handle: '@USGoldBureau'
            },
            titleTemplate: 'USGB Storefront | %s',
            description: 'USGB Commerce',
            openGraph: {
                type: 'website',
                locale: 'en_US',
                url: 'https://www.usgoldbureau.com/',
                site_name: 'USGB',
                images: [
                    {
                        url: 'https://www.usgoldbureau.com/unitedstatesgoldbureauimage.svg',
                        alt: 'USGB'
                    }
                ]
            }
        },
        product: {
            attributes: {
                name: ['name'],
                description: ['description', 'html'],
                image: ['media_gallery', '0', 'url'],
                price_amount: [
                    'price_range',
                    'minimum_price',
                    'final_price',
                    'value'
                ],
                price_currency: [
                    'price_range',
                    'minimum_price',
                    'final_price',
                    'currency'
                ],
                item_condition: null,
                gtin: ['gtin'],
                brand: ['brand'],
                manufacturer: ['manufacturer'],
                model: ['model'],
                sku: ['sku'],
                mpn: ['mpn'],
                seller: ['seller'],
                alternate_name: ['alternate_name'],
                review_count: ['review_count'],
                review_summary: ['review_summary']
            },
            image: {
                count: 1,
                width: 620,
                placeholder:
                    'https://mgi.usgoldbureau.com/static/frontend/Magento/luma/en_US/Magento_Catalog/images/product/placeholder/image.jpg'
            },
            description: {
                regExp: '^[\\n|\\n\\r]+Details[\\n|\\n\\r]+',
                maxLength: 1000
            },
            price: { currency: 'USD', precision: 2, validUntilDays: 14 },
            itemConditionMap: null,
            defaultBrand: 'USGoldBureau Products',
            defaultSeller: null,
            bestRating: 5
        },
        organization: {
            content: {
                '@context': 'https://schema.org',
                '@type': 'Corporation',
                address: {
                    '@type': 'PostalAddress',
                    streetAddress: '203 Heritage Grove Rd',
                    addressLocality: 'Leander',
                    addressRegion: 'TX',
                    addressCountry: 'US',
                    postalCode: '78641'
                },
                url: 'https://www.usgoldbureau.com/',
                logo: 'https://www.usgoldbureau.com/logo.svg',
                name: 'U.S. Gold Bureau',
                email: 'sales@usgoldbureau.com',
                logoArray: [
                    'https://www.usgoldbureau.com/usgb-logo-black-1500x1600.png',
                    'https://www.usgoldbureau.com/usgb-logo-gold-1500x1600.png',
                    'https://www.usgoldbureau.com/usgb-logo-white-1500x1600.png'
                ],
                alternateName: [
                    'USGB',
                    'US Gold Bureau',
                    'United States Gold Bureau',
                    'The United States Gold Bureau'
                ],
                legalName: 'USGB, LLC',
                description:
                    'Leading dealer of investment-quality gold bars and gold coins from the U.S. Mint and other suppliers on our website. Get free shipping & portfolio advice.',
                contactPoint: [
                    {
                        telephone: '+1-800-775-3504',
                        email: 'sales@usgoldbureau.com',
                        contactType: 'customer service',
                        contactOption: 'TollFree',
                        areaServed: 'US',
                        url: 'https://www.usgoldbureau.com/contact-us',
                        availableLanguage: {
                            name: 'English',
                            alternateName: 'en'
                        }
                    }
                ],
                sameAs: [
                    'https://www.facebook.com/USGoldBureau',
                    'https://twitter.com/USGoldBureau',
                    'https://www.linkedin.com/company/the-united-states-gold-bureau/',
                    'https://www.youtube.com/user/usgoldbureau',
                    'https://www.instagram.com/usgoldbureau/'
                ]
            },
            allowedPath: '/'
        },
        customPages: {
            account: {
                pageName: 'My Account',
                pageType: 'account',
                children: {
                    orders: {
                        pageName: 'My Account | Orders',
                        pageType: 'account-orders'
                    },
                    wishlist: {
                        pageName: 'My Account | Wishlist',
                        pageType: 'account-wishlist'
                    },
                    address: {
                        pageName: 'My Account | Address',
                        pageType: 'account-address'
                    },
                    'account-information': {
                        pageName: 'My Account | Account Information',
                        pageType: 'account-information'
                    },
                    'store-credit': {
                        pageName: 'My Account | Store Credit',
                        pageType: 'account-store-credit'
                    },
                    newsletter: {
                        pageName: 'My Account | Newsletter Subscription',
                        pageType: 'account-newsletter'
                    },
                    'gift-card': {
                        pageName: 'My Account | Gift Card',
                        pageType: 'account-gift-card'
                    }
                }
            },
            search: { pageName: 'Search page', pageType: 'searchresults' },
            checkout: { pageName: 'Checkout page', pageType: 'checkout' },
            cart: { pageName: 'Cart Page', pageType: 'cart' },
            guestOrder: {
                pageName: 'Order Information',
                pageType: 'guestorder'
            }
        },
        multiStoreUrlParamCount: 0,
        checkoutSteps: { cart: 1, shipping: 2, payment: 3 },
        tagManager: {
            gtm: {
                enabled: true,
                containerId: 'GTM-KCJF9DV',
                gA4Config: { id: '3Z3ETT805Q' }
            }
        },
        searchBox: {
            allowedPath: '/',
            potentialActions: [
                {
                    target: 'https://www.usgoldbureau.com/search?q',
                    queryInput: 'search_term_string'
                }
            ]
        }
    },
    static: {
        categories: { count: 50, root: 'default-category' },
        products: { count: 100 },
        news: { count: 20 }
    },
    is_fallback: true
}
